import React, { useLayoutEffect } from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';
import loadable from '@loadable/component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSackDollar } from '@fortawesome/pro-light-svg-icons';

import useStyles from './styles';

const Grid = loadable(() => import('@material-ui/core/Grid'));
const Paper = loadable(() => import('@material-ui/core/Paper'));
const Typography = loadable(() => import('@material-ui/core/Typography'));
const Hidden = loadable(() => import('@material-ui/core/Hidden'));

const LazyRegisterActionCta = loadable(() => import('components/cta/register-action'));

const TryFreeHeadline = ({ hash }) => {
  const styles = useStyles();

  useLayoutEffect(() => {
    const waitScroll = async () => {
      const scrollTo = await import('gatsby-plugin-smoothscroll');
      setTimeout(() => {
        scrollTo.default(hash);
      }, 2500);
    };
    if (hash) {
      waitScroll();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
    <span id="try-free"/>
    <Grid spacing={1}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
     >
      <Grid item xs={12} md={10} lg={8}>
        <Paper className={styles.paper} elevation={4}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          >
            <Grid item xs={12}>
              <Typography
                variant="h4"
                component="h2"
                className={classnames(styles.headerGreen, styles.fourTopBotMargin)}
                align="center">
                Try For Free
                <FontAwesomeIcon icon={faSackDollar} size="lg" className={styles.headerIcon}/>
                <Typography
                  variant="caption"
                  component="p"
                  align="right"
                  className={styles.headerSubText}>
                   Try Total Loyalty with all features enabled for free
                </Typography>
              </Typography>
            </Grid>

            <Grid item sm={10} xs={12}>
              <Hidden xsDown>
                <Typography
                  variant="h6"
                  component="p"
                  align="center"
                  className={classnames(styles.bodyNoBottomMargin, styles.fourBotMargin)}>
                  {'Build a Loyalty Card with the full functionality of Total Loyalty'}
                </Typography>
              </Hidden>
              <Hidden smUp>
                <Typography
                  variant="h6"
                  component="p"
                  align="center"
                  className={classnames(styles.bodyNoBottomMargin, styles.fourBotMargin)}>
                  {'Build a Loyalty Card with the full'} <br/> {'functionality of Total Loyalty'}
                </Typography>
              </Hidden>
            </Grid>

            <Grid item sm={10} xs={12}>
              <Typography
                variant="subtitle1"
                component="p"
                align="justify"
                className={classnames(styles.bodyNoBottomMargin, styles.fourBotMargin)}>
                {`We want you to be certain Total Loyalty is the right product to enable your business build better customer loyalty.
                Sign up to Total Loyalty and try our software for free. Build a pass with all the functionality Total Loyalty offers.`}
              </Typography>
            </Grid>

            <Grid item sm={10} xs={12}>
              <Typography
                variant="subtitle1"
                component="p"
                align="justify"
                className={classnames(styles.bodyNoBottomMargin, styles.fourBotMargin)}>
                {`Build a Loyalty Card and share it with an Android device for Google Pay, and a iOS device for
                  Apple Wallet.  We are always adding new functionality and every subscription including our free tier has full access
                  to all features.`}
              </Typography>
            </Grid>

            <Grid item xs={12} className={styles.fourBotMargin}>
              <Hidden xsDown>
              <LazyRegisterActionCta
                  primary="Get started with Total Loyalty"
                  primarySmall="Register now"
                  secondary="Try for free and build better Loyalty"
                  />
              </Hidden>
              <Hidden smUp>
                <LazyRegisterActionCta
                    primary="Get started with Total Loyalty"
                    primarySmall="Try for free"
                    secondary="Sign up and build better Loyalty"
                    />
              </Hidden>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
    </>
  );
};

TryFreeHeadline.propTypes = { hash: string };

export default TryFreeHeadline;
